
//------------------------------- Site header
.site-header {
	min-height: 28px;
	position: relative; // Positioning context for the mobile navigation icon
	padding: 28px 0px;
	text-align: center;
	width: 100%;
	// z-index: 99;
	// position: fixed;
	// left: 0;
	// top: 0;
}
.site-title {
	display: inline-block;
	margin-bottom: 15px;
}

//------------------------------- Post nav

nav {
	width: 100%;
	min-height: 30px;
}

/*** Arrows ***/

a.arrow {
	margin-top: -12px; // center vertically to align with slider
	color: $text-color;
	text-decoration: none;
	padding: 6px 15px 6px 15px;
	font-family: Verdana, Geneva, sans-serif;
	font-weight: bold;
	font-size: 28px;
	-webkit-transition: .2s ease-in;
	-moz-transition: .2s ease-in;
	-o-transition: .2s ease-in;
	transition: .2s ease-in;
}
a.arrow:hover {
	color: $background-color;
	background: $grey-color;
	-webkit-transition: .2s ease-out;
	-moz-transition: .2s ease-out;
	-o-transition: .2s ease-out;
	transition: .2s ease-out;
}
#arrow-left{ float: left; border-radius: 0 25px 25px 0; }
#arrow-left:hover{ padding-left: 12px; }
#arrow-right{ float: right; border-radius: 25px 0 0 25px; }
#arrow-right:hover{ padding-right: 12px; }

/*** Slider ***/

#slider{
	position: relative;
	margin: 0 60px 0 60px;
	height: 30px;
}
input[type="range"]{
	height: 30px;
	-webkit-appearance: none; /*removes default webkit styles*/
	border: 1px solid white; /*fix for FF unable to apply focus style bug */
	width: 100%; /*required for proper track sizing in FF*/
	margin: auto;
}

/*** Slider styling ***/

input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 2px;
	background: $grey-color-lighter;
	border: none;
	border-radius: 2px;
}
input[type=range]::-moz-range-track {
	width: 100%;
	height: 2px;
	background: $grey-color-lighter;
	border: none;
	border-radius: 2px;
}
input[type=range]::-ms-track {
	width: 100%;
	height: 2px;
	background: $grey-color-lighter;
	border: none;
	border-radius: 2px;
	color: transparent; /*remove default tick marks*/
}
input[type=range]::-ms-fill-lower {
	outline: none;
	background: $grey-color-light;
	border-radius: 10px 0 0 10px;
}


input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
	margin-top: -4px;

	border: none;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background: $text-color;
}
input[type=range]::-moz-range-thumb {
	border: none;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background: $text-color;
}
input[type=range]::-ms-thumb {
	border: none;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background: $text-color;
}


input[type=range]:focus { outline: none; }
input[type=range]:focus::-webkit-slider-runnable-track { background: $grey-color-light; }
input[type=range]:focus::-ms-track { background: $grey-color-light; }
input[type=range]:focus::-ms-fill-lower { background: $grey-color-light; }
input[type=range]:-moz-focusring{ /*hide the outline behind the border*/
	outline: 1px solid white;
	outline-offset: -1px;
}

/*** Slider tooltip ***/

input[type="range"]:hover + output {
	opacity: 1;
	-webkit-transition-delay: .2s;
	-moz-transition-delay: .2s;
	transition-delay: .2s;
}
input[type="range"] + output {
	opacity: 0;
	-webkit-transition: opacity .2s ease-in-out;
	-moz-transition: opacity .2s ease-in-out;
	transition: opacity .2s ease-in-out;
	-webkit-transition-delay: .4s;
	-moz-transition-delay: .4s;
	transition-delay: .4s;
	position: absolute;
	color: $background-color;
	background: $grey-color;
	text-align: center;
	font-size: 14px;
	line-height: 17px;
	font-weight: bold;
	width: 34px;
	height: 17px;
	border-radius: 5px;
	display: inline-block;
	bottom: -24px; // vertical position to slider thumb
	left: 0;
	margin-left: -16px; // horizontal centering to slider thumb
	padding: 4px 5px;
}
input[type="range"] + output:after {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	border-bottom: 6px solid $grey-color;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	bottom: 100%;
	left: 50%;
	margin-left: -4px;
	margin-top: -1px;
}

//------------------------------- Site footer

.site-footer {
//	border-top: 2px solid $grey-color-lighter;
	padding: $spacing-unit 0;
//    background: $background-color-navs;
	margin-top: $spacing-unit / 2;
}

.footer-col-wrapper {
	font-size: 15px;
	color: $grey-color;
	margin-left: -$spacing-unit / 2;
	@extend %clearfix;
}

.footer-col {
	float: left;
	padding-left: $spacing-unit / 2;
}
.footer-col-1,
.footer-col-2 {
	width: -webkit-calc(50% - (#{$spacing-unit} / 2));
	width:         calc(50% - (#{$spacing-unit} / 2));
}
.footer-col-2 {
	text-align:right;
}

@include media-query($on-laptop) {
	.footer-col-1,
	.footer-col-2 {
		width: -webkit-calc(50% - (#{$spacing-unit} / 2));
		width:         calc(50% - (#{$spacing-unit} / 2));
	}
}
@include media-query($on-palm) {
	.footer-col {
		float: none;
		width: -webkit-calc(100% - (#{$spacing-unit} / 2));
		width:         calc(100% - (#{$spacing-unit} / 2));
	}
	.footer-col-2 {
		text-align:left;
	}
}

#site-quote {
	text-align: center;
//	margin: $spacing-unit 0 ($spacing-unit*2) 0;
	margin-bottom: $spacing-unit*2;
	color: $grey-color;
}

//------------------------------- Page content

.page-content {
	// padding: 95px 0 0 0;
//    padding: $spacing-unit 50px;
}

.page-heading {
	font-size: 20px;
}

.post-list {
	margin-left: 0;
	list-style: none;

	> li {
		margin-bottom: $spacing-unit;
	}
}

.post-meta {
	font-size: $small-font-size;
	color: $grey-color;
}

.post-link {
	display: block;
	font-size: 24px;
}

//------------------------------- Posts

.post-header {
   margin-bottom: $spacing-unit;
   text-align: center;
}
.post-title {
	font-size: 36px;
	letter-spacing: -1px;
	line-height: 1;
	text-shadow: $text-shadow;
	@include media-query($on-laptop) {
		font-size: 34px;
	}
}
.post-footer {
	margin-bottom: $spacing-unit;
}
//.post-content {

	h2 {
		font-size: 32px;
		@include media-query($on-laptop) {
			font-size: 28px;
		}
	}

	h3 {
		font-size: 26px;
		@include media-query($on-laptop) {
			font-size: 22px;
		}
	}

	h4 {
		font-size: 20px;
		@include media-query($on-laptop) {
			font-size: 18px;
		}
	}
//}

//------------------------------- Post types

.link h1.post-title {
	font-size: 48px;
}
.image article p:first-child,
.video article .videoWrapper,
.quote article blockquote {
	margin-bottom: $spacing-unit;
	text-align: center;
}
.quote {
	padding-left: 50px;
}
.quote article blockquote {
	text-align: left;
	font-size: 48px;
	letter-spacing: -1px;
	line-height: 1;
	text-decoration: none;
	color: #111;
	padding: 0;

	display:block;
	position: relative;
	border-left: 0;

	text-shadow: $text-shadow;

	@include media-query($on-laptop) {
		font-size: 42px;
	}
}
.quote article blockquote:before {
	content: "\201C";
	font-family: Georgia, serif;
	font-size: 68px;
	font-weight: bold;
	color: #111;
	position: absolute;
	left:-50px;
	top:0;
	text-shadow: $text-shadow;
}
.quote article blockquote p {
	display: inline;
}
.quote article .post-header h1 {
	text-align:left;
	font-size: 24px;
	//padding: 0 0 0 50px;
}
.video .videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.video .videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

//------------------------------- Ads

.ad {
	margin-top: 1px solid $grey-color-light;
	margin-bottom: 1px solid $grey-color-light;
}

//------------------------------- Disqus

#show_comments{
	font-size: $base-font-size;
	display:block;
	text-align: center;
	cursor: pointer;
}

#disqus_thread{
	background-color: $grey-color-lightest;
	padding: 1em;
	margin-top: 2.2em;
	display:none; // will be show by js
}

//------------------------------- Cookiebar

#cookie-bar {background:#a5b2b9; height:auto;  color:#fff; text-align:center; padding:8px 0;}
#cookie-bar.fixed {position:fixed; top:0; left:0; width:100%;}
#cookie-bar.fixed.bottom {bottom:0; top:auto;}
#cookie-bar p {margin:0; padding:0;}
#cookie-bar a {color:#fff; text-decoration:none; font-weight: bold;}
#cookie-bar a:hover {text-decoration:underline;}
#cookie-bar .cb-enable { font-weight: normal; background:#D1D9DE; color: #667178; display:inline-block; border-radius:3px; text-decoration:none; padding:0 6px; margin:0 18px 0 18px;}
#cookie-bar:hover .cb-enable { text-decoration:none; }
#cookie-bar .cb-enable:hover {background:#DCE2E6;}
