@charset "utf-8";

// Our variables
$base-font-family: 	'Helvetica Neue', Helvetica, Arial, sans-serif;
$base-font-size:   	16px;

$small-font-size:  	$base-font-size * 0.813;
$base-line-height: 	1.5;

$spacing-unit:     	30px;

$text-color:       	#3f4549;
$background-color: 	#ffffff;
$link-color:      	#2a7ae2;

//$grey-color:       	#828282;
//$grey-color-light: 	lighten($grey-color, 40%);
//$grey-color-dark:  	darken($grey-color, 25%);

$grey-color:       	#a5b2b9;
$grey-color-light: 	#ccd4d8;
$grey-color-lighter:	#eaedee;
$grey-color-lightest:	#f7f7f7;
$grey-color-dark:  	#777f84;

$on-palm:			600px;
$on-laptop:			800px;

$text-shadow:		0 1px 0 rgba(255, 255, 255, 1);

// Using media queries with like this:
// @include media-query($palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}
// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout"
;
